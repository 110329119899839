<!--
 * @Author: huwanjun
 * @Date: 2021-04-07 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 12:27:22
 * @Description: 产线详情
-->
<template>
  <NeoDrawerLayout :showFooter="true">
    <template v-if="neoVersion === 1">
      <div class="productionLineDetails">
        <h2 class="title mb-4">{{ $t('PlineDetails.basic_setup') }}</h2>
        <div class="base card_bg">
          <NeoTitle :text="$t('PlineDetails.title_basic_config')" class="tips" />
          <a-row>
            <a-col :span="12">
              <div class="text">
                <p class="text_title">
                  {{ $t('PlineDetails.industry_field') }}：
                  <span>{{ industryField }}</span>
                </p>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="text">
                <p class="text_title">
                  {{ $t('PlineDetails.product_dock') }}：<span class="text_content">{{
                    localeDict.systemDict[details.produceVersion]
                  }}</span>
                </p>
              </div>
            </a-col>
          </a-row>
          <NeoTitle :text="$t('PlineDetails.title_offer_config')" class="tips" />
          <div class="text">
            <p class="text_title">
              {{ $t('PlineDetails.default_offer_mode') }}：<span class="text_content">{{
                getQuoteType(details.quoteType)
              }}</span>
            </p>
          </div>
          <a-descriptions bordered layout="vertical" :column="9" size="small">
            <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
              $t('PlineDetails.discount_ratio')
            }}</a-descriptions-item>
            <a-descriptions-item
              v-for="(item, index) in details.discountList"
              :key="index"
              :label="item.discountRange"
              >{{ item.discountValue }}</a-descriptions-item
            >
          </a-descriptions>
        </div>
        <h2 class="title mb-4 mt-4">{{ $t('PlineDetails.Neo_setup') }}</h2>
        <div class="Neotrans card_bg">
          <NeoTitle :text="$t('PlineDetails.title_basic_config')" class="tips" />
          <a-row>
            <a-col :span="3">
              <span class="text text_title">{{ $t('PlineDetails.client_project') }}：</span>
            </a-col>
            <a-col :span="9">
              <span class="text text_content">{{ details.produceClientProjectName }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3">
              <span class="text text_title">{{ $t('PlineDetails.job_type') }}：</span>
            </a-col>
            <a-col :span="9">
              <span class="text text_content">{{ details.produceJobType ? 'File-Based' : '' }}</span>
            </a-col>
            <a-col :span="3">
              <span class="text text_title">{{ $t('PlineDetails.ratio') }}：</span>
            </a-col>
            <a-col :span="9">
              <span class="text text_content">{{
                details.produceRatio ? details.produceRatioName : 'System Default'
              }}</span>
            </a-col>
          </a-row>
          <NeoTitle :text="$t('PlineDetails.title_corpus_config')" class="tips" />
          <a-row>
            <a-col :span="12" :push="1">
              <div class="text">
                <p class="text_title">
                  {{ $t('PlineDetails.engine') }}：
                  <template v-if="details.produceMtEngineName != null">
                    <span class="text_content"
                      >{{ details.produceMtEngineName }} - {{ localeDict.produceMtApply[details.produceMtApply] }}</span
                    >
                  </template>
                </p>
              </div>
            </a-col>
            <a-table
              class="head-bg no-br mt-1 mb-4"
              bordered
              :data-source="details.documentList"
              :pagination="false"
              size="middle"
              rowKey="documentType"
              :loading="loading"
            >
              <a-table-column :title="$t('PlineDetails.doc_type')" key="documentType" width="25%">
                <template slot-scope="text">{{ getDocType(text.documentType) }}</template>
              </a-table-column>
              <a-table-column :title="$t('PlineDetails.tm_and_term')" key="produceTmTermGroup" width="35%">
                <template slot-scope="text">
                  {{ text.produceTmTermGroupName }}
                </template>
              </a-table-column>
              <a-table-column :title="$t('PlineDetails.tm_reverse')" align="center" key="produceTmReverseApply">
                <template slot-scope="text">
                  <a-switch size="small" :checked="text.produceTmReverseApply == 1"
                /></template>
              </a-table-column>
            </a-table>
          </a-row>
          <NeoTitle :text="$t('PlineDetails.title_person_config')" class="tips" />
          <div class="person_config">
            <a-form-model ref="form" :model="formData" :rules="rules">
              <a-row>
                <a-col :span="3">
                  <p class="text text_title">{{ $t('PlineDetails.delivery_manager') }}：</p>
                </a-col>
                <a-col :span="9">
                  <template v-if="edit">
                    <a-form-model-item prop="supplierPm">
                      <a-select
                        show-search
                        size="small"
                        v-model="formData.supplierPm"
                        class="select-input"
                        :filter-option="filterOption"
                      >
                        <a-select-option v-for="(item, index) in pmList" :key="index" :value="item.id">{{
                          `${item.realName}(${item.email})`
                        }}</a-select-option></a-select
                      >
                    </a-form-model-item>
                  </template>
                  <template v-else>
                    <span class="text_content">{{ `${details.supplierPmName}(${details.supplierPmEmail})` }}</span>
                  </template>
                </a-col>
                <a-col :span="4">
                  <p class="text text_title">{{ $t('PlineDetails.product_manager') }}：</p>
                </a-col>
                <a-col :span="8" :pull="1">
                  <template v-if="edit">
                    <a-form-model-item prop="producePm">
                      <a-select
                        size="small"
                        class="select-input"
                        v-model="formData.producePm"
                        mode="multiple"
                        :filter-option="filterOption"
                        ><a-select-option v-for="(item, index) in produceManagerList" :key="index">{{
                          `${item.displayName}(${item.username})`
                        }}</a-select-option></a-select
                      >
                    </a-form-model-item>
                  </template>
                  <template v-else>
                    <span class="text_content mr-2" v-for="(item, index) in details.produceManagerList" :key="index">{{
                      `${item.producePmName}(${item.producePm})`
                    }}</span>
                  </template>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </div>
      </div>
    </template>

    <template v-if="neoVersion === 2">
      <div class="productionLineDetails">
        <h2 class="title mb-4">{{ $t('PlineDetails.basic_setup') }}</h2>
        <div class="base card_bg">
          <NeoTitle :text="$t('PlineDetails.title_basic_config')" class="tips" />
          <a-row>
            <a-col :span="12">
              <div class="text">
                <p class="text_title">
                  {{ $t('PlineDetails.industry_field') }}：
                  <span>{{ industryField }}</span>
                </p>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="text">
                <p class="text_title">
                  {{ $t('PlineDetails.product_dock') }}：<span class="text_content">{{
                    localeDict.systemDict[details.produceVersion]
                  }}</span>
                </p>
              </div>
            </a-col>
          </a-row>
          <NeoTitle :text="$t('PlineDetails.title_offer_config')" class="tips" />
          <div class="text">
            <p class="text_title">
              {{ $t('PlineDetails.default_offer_mode') }}：<span class="text_content">{{
                getQuoteType(details.quoteType)
              }}</span>
            </p>
          </div>
          <a-descriptions bordered layout="vertical" :column="11" size="small">
            <a-descriptions-item :label="$t('PlineDetails.match_range')">{{
              $t('PlineDetails.discount_ratio')
            }}</a-descriptions-item>
            <a-descriptions-item
              v-for="(item, index) in details.discountList"
              :key="index"
              :label="item.discountRange"
              >{{ item.discountValue }}</a-descriptions-item
            >
          </a-descriptions>
        </div>
        <h2 class="title mb-4 mt-4">{{ $t('PlineDetails.Neo_setup') }}</h2>
        <div class="Neotrans card_bg">
          <NeoTitle :text="$t('PlineDetails.title_basic_config')" class="tips" />
          <div class="basic_config">
            <a-form-model :colon="false" ref="neoForm" :model="newNeoForm">
              <a-row>
                <a-col>
                  <a-form-model-item
                    :label="$t('PlineDetails.program')"
                    :label-col="{ span: 2 }"
                    :wrapper-col="{ span: 22 }"
                    prop="program"
                    :rules="[validate.required]"
                  >
                    <a-select
                      v-model="details.productLineProgramIds"
                      mode="multiple"
                      :disabled="!edit"
                      @change="onSelectProgram"
                    >
                      <a-select-option v-for="(item, index) in programList" :key="index" :value="item.id">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <a-form-model-item
                    style="display: flex"
                    :label="$t('PlineDetails.new_program_client_project')"
                    :label-col="{ flex: '150px' }"
                    :wrapper-col="{ flex: 'auto' }"
                    prop="clientProject"
                    :rules="[validate.required]"
                  >
                    <a-select :disabled="!edit" v-model="newNeoForm.clientProject">
                      <a-select-option v-for="(item, index) in namePidList" :key="index" :value="item.id">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
          <a-table
            class="head-bg"
            :rowClassName="fontIsRed"
            :pagination="pagination"
            size="small"
            :data-source="tableData"
            :loading="loading"
          >
            <a-table-column title="Program">
              <template slot-scope="text">{{ text.name }}</template>
            </a-table-column>
            <a-table-column title="Source">
              <template slot-scope="text">{{ text.sourceCode }}</template>
            </a-table-column>
            <a-table-column title="Content Type">
              <template slot-scope="text">{{ text.componentName }}</template>
            </a-table-column>
          </a-table>
          <div class="attention">
            <p>{{ $t('newPline.attention') }}{{ $t('newPline.attention_1') }}</p>
            <!-- <p>{{ $t('newPline.attention_1') }}</p>
            <p>{{ $t('newPline.attention_2') }}</p>
            <p>{{ $t('newPline.attention_3') }}</p> -->
          </div>
          <NeoTitle :text="$t('PlineDetails.title_person_config')" class="tips mt-2" />
          <div class="person_config">
            <a-form-model ref="form" :model="newNeoForm" :rules="rules">
              <a-row>
                <a-col :span="3">
                  <p class="text text_title">{{ $t('PlineDetails.delivery_manager') }}：</p>
                </a-col>
                <a-col :span="9">
                  <template v-if="edit">
                    <a-form-model-item prop="supplierPm" :rules="[validate.required]">
                      <a-select
                        show-search
                        size="small"
                        v-model="newNeoForm.supplierPm"
                        class="select-input"
                        :filter-option="filterOption"
                      >
                        <a-select-option v-for="(item, index) in pmList" :key="index" :value="item.id">{{
                          `${item.realName}(${item.email})`
                        }}</a-select-option></a-select
                      >
                    </a-form-model-item>
                  </template>
                  <template v-else>
                    <span class="text_content">{{ `${details.supplierPmName}(${details.supplierPmEmail})` }}</span>
                  </template>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer v-if="updatePermission">
      <a-space>
        <template v-if="edit">
          <a-button @click="edit = false">{{ $t('cancel') }}</a-button>
          <a-button v-if="neoVersion === 1" @click="onSave" :loading="saveLoading" type="primary" class="btn">{{
            $t('save')
          }}</a-button>
          <a-button v-if="neoVersion === 2" @click="onSaveNewNeo" :loading="saveLoading" type="primary" class="btn">{{
            $t('save')
          }}</a-button>
        </template>
        <a-button v-else @click="edit = true" type="primary" class="btn">{{ $t('change') }}</a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>
<script>
const { validate, localeDict } = window.$g
export default {
  props: ['id', 'updatePermission', 'neoVersion', 'agencyId', 'clientName'],
  data() {
    return {
      localeDict,
      // 报价配置
      validate,
      details: [],
      edit: false,
      produceManagerList: [],
      pmList: [],
      formData: {
        producePm: [],
        supplierPm: '',
      },
      newNeoForm: {
        program: undefined,
        clientProject: undefined,
        supplierPm: undefined,
      },
      rules: {
        producePm: [validate.required],
        supplierPm: [validate.required],
      },
      filed: '',
      saveLoading: false,
      loading: false,
      industryField: '',
      pagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 5,
        style: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        // eslint-disable-next-line no-unused-vars
        onChange: (page) => {
          this.pagination.current = page
        },
      },
      tableData: [],
      programList: [],
      namePidList: [],
    }
  },
  mounted() {
    this.neoVersion === 1 ? this.getProductionLineDetails() : this.getProductionLineDetails_2()
    this.getProgramList()
    this.getPmList()
    this.getProduceManagerList()
    this.getNamePidList()
  },
  methods: {
    fontIsRed(record) {
      const table = this.tableData
      const { id: myid, sourceCode, componentId } = record
      const isred = table.some((item) => {
        if (myid !== item.id) {
          return item.sourceCode === sourceCode && item.componentId === componentId
        } else {
          return false
        }
      })
      if (isred) {
        return 'redfont'
      } else {
        return ''
      }
    },
    onSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('updateProductionLine', {
              productId: this.id,
              produceManagerList: this.formData.producePm.map((item) => {
                let data = []
                this.produceManagerList.forEach((v) => {
                  if (v.username === item) {
                    let result = {}
                    result.producePm = v.username
                    result.producePmEmails = v.emails
                    result.producePmName = v.displayName
                    data.push(result)
                  }
                })
                return data[0]
              }),
              supplierPm: this.formData.supplierPm,
              pid: this.details.produceClientProject,
            })
            this.saveLoading = false
            this.edit = false
            this.getProductionLineDetails()
            this.$emit('update')
          } catch (err) {
            this.saveLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    onSaveNewNeo() {
      const table = this.tableData
      const arr = []
      console.log(`table`, table)
      if (table.length >= 2) {
        table.forEach((item1) => {
          const isSame = table.some((item2) => {
            if (item1.id !== item2.id) {
              return item2.sourceCode === item1.sourceCode && item2.componentId === item1.componentId
            } else {
              return false
            }
          })
          if (isSame) {
            arr.push(item1)
          }
        })
        console.log(`arr`, arr)
        if (arr.length > 0) {
          let tooltop = ''
          let names = ''
          arr.forEach((item, idx) => {
            if (idx === 0) {
              names = `"${item.name}"`
            } else {
              names = names + '、' + `"${item.name}"`
            }
          })
          tooltop = names
          console.log(`tooltop：`, tooltop)
          return this.$message.error(this.$t('newPline.source_content', { tooltop }), 5)
        }
      }
      this.$refs.neoForm.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true
          try {
            const { program = [] } = this.newNeoForm
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('updateProductionLine_2', {
              pid: this.newNeoForm.clientProject,
              supplierPm: this.newNeoForm.supplierPm,
              supplierPmName: this.newNeoForm.supplierPm,
              productId: this.id,
              updateProgramLineRequestList: program.map((id) => {
                const programItem = this.programList.find((pInfo) => pInfo.id === id) || {}
                return {
                  pid: programItem.clientProjectId,
                  programId: programItem.id,
                  programName: programItem.name,
                  contentType: programItem.componentId,
                  contentTypeName: programItem.componentName,
                  source: programItem.sourceCode,
                }
              }),
            })
            this.saveLoading = false
            this.edit = false
            this.getProductionLineDetails_2()
            this.$emit('update')
          } catch (err) {
            this.saveLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 根据产线id获取产线详情
    async getProductionLineDetails() {
      this.loading = true
      try {
        const data = await this.$http({
          key: 'productionLineDetails',
          params: { id: this.id },
        })
        this.details = data
        this.industryField = this.getField(this.details.field)
        this.formData.supplierPm = data.supplierPm
        this.formData.producePm = data.produceManagerList.map((item) => item.producePm)
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 根据产线id获取产线详情(新版)
    async getProductionLineDetails_2() {
      this.loading = true
      try {
        const data = await this.$http({
          key: 'productionLineDetails_2',
          params: { id: this.id },
        })
        this.details = data
        this.industryField = this.getField(this.details.field)
        this.newNeoForm.program = data.productLineProgramIds
        this.newNeoForm.supplierPm = data.supplierPm
        this.newNeoForm.clientProject = data.pid
        // 根据productLineProgramIds查找对应的表格数据
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 获取programList
    async getProgramList() {
      try {
        const data = await this.$http('programList', {
          agencyId: this.agencyId,
          productId: this.id,
        })
        this.programList = data
        console.log(`this.details`, this.details)
        this.details.productLineProgramIds?.forEach((val) => {
          let result = this.programList.filter((item) => {
            if (item.id === val) {
              return item
            }
          })
          this.tableData.push(...result)
          console.log(this.tableData)
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 对接鲸译-获取clientproject
    async getNamePidList() {
      try {
        const data = await this.$http('namePidList', {
          name: this.clientName,
        })
        this.namePidList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取交付经理
    async getPmList() {
      try {
        const data = await this.$http('getPmList')
        this.pmList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取生产管理员
    async getProduceManagerList() {
      try {
        const data = await this.$http('getproduceManagerList')
        this.produceManagerList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    getVersion(val) {
      const data = this.$store.getters['app/getDictLabel']('system', val)
      return data
    },
    getQuoteType(val) {
      return val == 1 ? this.$t('companyInfo.artificial_offer') : this.$t('companyInfo.automatic_offer')
    },
    getRatio() {
      const data = this.$store.getters['app/getDictLabel']('RATIO', '1;0')
      return data
    },
    // 获取文档类型
    getDocType(val) {
      return this.$store.getters['app/getDictLabel']('DOC_TYPE', val)
    },
    // 获取领域
    getField(val) {
      const data = this.$store.getters['app/getIndustryName'](val)
      const result = data.length === 2 ? `${data[0]} / ${data[1]}` : `${data[1]} / ${data[2]}`
      return result
    },
    // 搜索下拉
    filterOption(input, option) {
      const data = option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      return data
    },
    onSelectProgram(value) {
      console.log(`value`, value)
      this.newNeoForm.program = this.details.productLineProgramIds

      this.tableData = []
      this.updateProgramLineRequestList = []
      value.forEach((val) => {
        // let that = this
        let result = this.programList.filter((item) => {
          if (item.id === val) {
            this.updateProgramLineRequestList.push({
              pid: undefined,
              programId: item.id,
              programName: item.name,
              contentType: item.componentId,
              contentTypeName: item.componentName,
              source: item.sourceCode,
            })
            return item
          }
        })
        this.tableData.push(...result)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.productionLineDetails {
  .title {
    font-size: @font_size_4;
    font-weight: bold;
    color: @text_color_1;
  }
  .tips {
    margin-bottom: 15px;
  }
  .text {
    font-weight: 400;
    font-size: @font_size_1;
  }
  .text_title {
    color: @text_color_1;
  }
  .text_content {
    color: @text_color_2;
  }
  /deep/.ant-col-3 {
    text-align: right;
    display: block;
    box-sizing: border-box;
    width: 10.5%;
    margin-bottom: 10px;
  }
  .card_bg {
    background: @background-color_3;
    background: @background-color_2;
    border: 1px solid rgba(212, 218, 229, 0.5019607843137255);
    box-shadow: 0px 6px 16px rgba(189, 197, 211, 0.2);
    border-radius: @border-radius_1;
  }
  .base {
    padding: 22px 20px 20px;
    /deep/.ant-descriptions-row {
      text-align: center;
      font-size: @font_size_1;
    }
    /deep/.ant-descriptions-item-label {
      font-weight: bold;
      color: @text_color_2;
    }
  }
  .Neotrans {
    padding: 22px 20px 10px;
  }
  .person_config {
    .select-input {
      width: 250px;
      transform: translateY(-10px);
    }
  }
  .btn {
    min-width: 100px !important;
  }
  .attention {
    margin-top: 50px;
    p {
      margin: 0;
      color: #f56c6c;
    }
  }
  .basic_config {
    /deep/.ant-form-item {
      margin-bottom: 10px;
    }
  }
  //设置padding
  ::v-deep .ant-table-body {
    tr > th:first-child {
      padding-left: 45px !important;
    }
    // tr > th:last-child {
    //   padding-right: 45px !important;
    // }
    .ant-table-tbody {
      & > tr > td:first-child {
        padding-left: 45px !important;
      }
      & > tr > td:last-child {
        padding-right: 45px !important;
      }
      .redfont {
        color: #f56c6c;
      }
    }
  }
}
</style>
